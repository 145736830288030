import { FC, ReactElement } from 'react';

import { SafeHtml } from '@/components';
import { Picture, VideoTheatre } from '@/compositions';
import { ContentColumnContent } from '@/entities/@blocks/ContentColumns/ContentColumns';

import './ContentColumn.scss';

interface ContentColumnProps extends ContentColumnContent {
    className?: string;
}

export const ContentColumn: FC<ContentColumnProps> = ({
    html,
    image,
    video,
    className = '',
}): ReactElement => (
    <div className={`content-column ${className}`}>
        {!(image || video) && (
            <SafeHtml
                html={html}
                className="content-column__html"
            />
        )}

        {image && !video && (
            <Picture
                {...image}
                className="content-column__image"
            />
        )}

        {video && (
            <VideoTheatre
                {...video}
                poster={image}
                className="content-column__video"
            />
        )}
    </div>
);
