import { combineSlices } from '@reduxjs/toolkit';

import toastSlice, { ToastState } from './toast/toastSlice';

export interface Slices {
    toastSlice: ToastState;
}

export default combineSlices(
    toastSlice,
);
