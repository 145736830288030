'use client';

import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { thunk } from 'redux-thunk';

import { isProduction } from '@/helpers';

import slices, { Slices } from './slices';

export type TypedDispatch = ThunkDispatch<Slices, unknown, UnknownAction>;
export type SliceGetter = () => Slices;

export const useTypedDispatch = (): TypedDispatch => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Slices> = useSelector;

export const store = configureStore({
    reducer: slices,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(thunk),
    // TODO: Implement proloaded state for SSR, perhaps?
    devTools: !isProduction,
});
