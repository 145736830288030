import { FC, ReactElement } from 'react';

import { SafeHtml, Wrapper } from '@/components';
import { LinkButton, Picture, Video } from '@/compositions';
import { Link } from '@/entities/Link/Link';
import { MediaItem } from '@/entities/Media/Media';

import './IntroductionBlock.scss';

interface IntroductionBlockProps {
    media: MediaItem;
    title: string;
    descriptionHtml: string;
    link: Link;
    className?: string;
}

export const IntroductionBlock: FC<IntroductionBlockProps> = ({
    media,
    title,
    descriptionHtml,
    link,
    className = '',
}): ReactElement => (
    <div className={`introduction-block ${className}`}>
        <Wrapper className="introduction-block__wrapper">
            <div className="introduction-block__media-wrapper">
                {media.image && (
                    <Picture
                        {...media.image}
                        className="introduction-block__picture"
                        imageClassName="introduction-block__image"
                    />
                )}

                {media.video && (
                    <Video
                        {...media.video}
                        autoPlay
                        className="introduction-block__video"
                    />
                )}
            </div>

            <div className="introduction-block__text-wrapper">
                <h2 className="introduction-block__title">
                    {title}
                </h2>

                <SafeHtml
                    html={descriptionHtml}
                    className="introduction-block__description-html"
                />

                <LinkButton
                    href={link.href}
                    text={link.label}
                    className="introduction-block__button"
                />
            </div>
        </Wrapper>
    </div>
);
