'use client';

import { FC, ReactElement } from 'react';

import { ToastList } from '@/containers';
import { useTypedDispatch, useTypedSelector } from '@/redux/store';
import { removeToast } from '@/redux/toast/toastActions';

export const ConnectedToastList: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const toastList = useTypedSelector(state => state.toastSlice.toastList);

    const handleToastClose = (toastUuid: string): void => {
        dispatch(removeToast(toastUuid));
    };

    return (
        <ToastList
            toasts={toastList}
            onToastClose={handleToastClose}
        />
    );
};
