import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Wrapper } from '@/components';
import { Picture, VideoTheatre } from '@/compositions';
import { MediaGridInterface } from '@/entities/@blocks/MediaGrid/MediaGrid';

import './MediaGrid.scss';

interface MediaGridProps extends MediaGridInterface {
    className?: string;
}

export const MediaGrid: FC<MediaGridProps> = ({
    items,
    className = '',
}): ReactElement => {
    const mediaGridClassNames = classNames('media-grid', {
        [`media-grid--layout-${items.length}`]: items.length,
    }, className);

    return (
        <div className={mediaGridClassNames}>
            <Wrapper className="media-grid__wrapper">
                {items.map(item => (
                    <div key={item.id} className="media-grid__media-wrapper">
                        {item.image && !item.video && (
                            <Picture
                                {...item.image}
                                className="media-grid__picture"
                                imageClassName="media-grid__image"
                            />
                        )}

                        {item.video && (
                            <VideoTheatre
                                {...item.video}
                                poster={item.image}
                                className="media-grid__video-theatre"
                            />
                        )}
                    </div>
                ))}
            </Wrapper>
        </div>
    );
};
