'use client';

import { FC, ReactElement } from 'react';

import { UrgentBanner } from '@/containers';
import { LinkTarget } from '@/entities/Link/Link';

export const ConnectedUrgentBanner: FC = (): ReactElement => {
    const mockUrgentBar = {
        text: 'Onze winkels zijn 2e pinksterdag open!',
        link: {
            label: '👉 Bekijk openingstijden',
            href: '/',
            target: LinkTarget.self,
        },
        backgroundColor: '#000',
        textColor: '#fff',
    };

    return (
        <UrgentBanner
            text={mockUrgentBar.text}
            link={mockUrgentBar.link}
            backgroundColor={mockUrgentBar.backgroundColor}
            textColor={mockUrgentBar.textColor}
        />
    );
};
