import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Toast } from '@/entities/Toast/Toast';

export type ToastState = {
    toastList: Toast[];
};

const initialState: ToastState = {
    toastList: [],
};

const toastSlice = createSlice({
    name: 'toastSlice',
    initialState,
    reducers: {
        setToastList(state, action: PayloadAction<Toast[]>): ToastState {
            return {
                ...state,
                toastList: action.payload,
            };
        },
    },
});

export const {
    setToastList,
} = toastSlice.actions;

export default toastSlice;
