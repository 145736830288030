import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { RootPortal } from '@/components';
import { Toast } from '@/compositions';
import { Toast as ToastModel, ToastPosition } from '@/entities/Toast/Toast';

import './ToastList.scss';

interface ToastListProps {
    toasts: ToastModel[];
    position?: ToastPosition;
    onToastClose: (toastId: string) => void;
    className?: string;
}

export const ToastList: FC<ToastListProps> = ({
    toasts,
    position = ToastPosition.blockEndInlineEnd,
    onToastClose,
    className = '',
}): ReactElement => {
    const toastListClassNames = classNames('toast-list', {
        'toast-list--inset-block-start': position.startsWith('block-start-'),
        'toast-list--inset-block-end': position.startsWith('block-end-'),
        'toast-list--inset-inline-start': position.endsWith('-inline-start'),
        'toast-list--inset-inline-end': position.endsWith('-inline-end'),
    }, className);

    return (
        <RootPortal>
            <ul className={toastListClassNames}>
                {toasts.map(toast => {
                    const handleClose = (): void => onToastClose(toast.id);

                    return (
                        <li key={toast.id} className="toast-list__item">
                            <Toast
                                {...toast}
                                position={position}
                                onClose={handleClose}
                                className="toast-list__toast"
                            />
                        </li>
                    );
                })}
            </ul>
        </RootPortal>
    );
};
