import { CSSProperties, FC, ReactElement } from 'react';

import { LinkButton, Picture, Video } from '@/compositions';
import { Link as LinkInterface } from '@/entities/Link/Link';
import { MediaItem } from '@/entities/Media/Media';

import './BannerBlock.scss';

interface BannerBlockProps {
    media: MediaItem;
    title?: string;
    titleColor?: string;
    subTitle?: string;
    subTitleColor?: string;
    link: LinkInterface;
    linkBackgroundColor?: string;
    className?: string;
}

export const BannerBlock: FC<BannerBlockProps> = ({
    media,
    title,
    titleColor,
    subTitle,
    subTitleColor,
    link,
    linkBackgroundColor,
    className = '',
}): ReactElement => {
    const cssVariables = {
        '--c-banner-title': titleColor,
        '--c-banner-sub-title': subTitleColor,
        '--c-banner-button-background': linkBackgroundColor,
    } as CSSProperties;

    return (
        <div style={cssVariables} className={`banner-block ${className}`}>
            <div className="banner-block__media-wrapper">
                {media.image && (
                    <Picture
                        {...media.image}
                        className="banner-block__picture"
                        imageClassName="banner-block__image"
                    />
                )}

                {media.video && (
                    <Video
                        {...media.video}
                        playsInline
                        autoPlay
                        muted
                        className="banner-block__video"
                        videoClassName="banner-block__video-element"
                    />
                )}
            </div>

            <div className="banner-block__cta-wrapper">
                <div className="banner-block__cta-contents-wrapper">
                    {title && (
                        <h2 className="banner-block__title">
                            {title}
                        </h2>
                    )}

                    {subTitle && (
                        <p className="banner-block__sub-title">
                            {subTitle}
                        </p>
                    )}

                    <LinkButton
                        href={link.href}
                        text={link.label}
                        className="banner-block__link-button"
                    />
                </div>
            </div>
        </div>
    );
};
